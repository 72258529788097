<div class="section testimonials">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">{{getWebsiteText('home.reviewtitle')}}</h1> 
            <div class="testimonials-carousel mt-5"> 
                <div class="swiper-container h-100" [swiper]="config"> 
                    <div class="swiper-wrapper h-100">      
                        <div *ngFor="let review of reviews" class="swiper-slide">
                            <div class="content text-center">
                                <img [attr.data-src]="review?.image" class="swiper-lazy">
                                <div class="quote open text-left primary-color">“</div>
                                <p class="text">{{review?.comment}}</p>
                                <div class="quote close text-right primary-color">”</div> 
                                <h2 class="secondary-font primary-color">{{review.name}}</h2>
                                <p>{{review.dateRegistered1 | date: 'fullDate'}}</p> 
                                <div class="swiper-lazy-preloader"></div>
                            </div>
                        </div>
                    </div> 
                    <div class="swiper-pagination"></div> 
                </div>
            </div> 
        </div>
    </div>   
</div> 