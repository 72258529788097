<form *ngIf="!emailSent" [formGroup]="form" (ngSubmit)="submit()" fxLayout="row wrap" class="reservation-form" autocomplete="off">
    <div *ngIf="data" fxFlex="100" fxFlex.gt-sm="100" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1"> 
         <button mat-raised-button color="primary" type="submit" class="uppercase">je choisis</button>
        <img [src]="data" width="200px" height="200px">
    </div> 
    
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100"> 
            <mat-label>Nom</mat-label>
            <input matInput placeholder="Your Full Name" formControlName="fullName" required>
            <mat-icon matSuffix class="text-muted">person</mat-icon>
            <mat-error *ngIf="form.controls.fullName.errors?.required">Nom est requis</mat-error>
        </mat-form-field> 
    </div>
    <div fxFlex="100" fxFlex.gt-sm="50" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1"> 
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Telephone</mat-label>
            <input matInput placeholder="Your Phone Number" formControlName="phoneNumber" required appPhoneMask>
            <mat-icon matSuffix class="text-muted">call</mat-icon>
            <mat-error *ngIf="form.controls.phoneNumber.errors?.required">Telephone est requis</mat-error>
        </mat-form-field> 
    </div>
    <div fxFlex="100" fxFlex.gt-sm="100" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1"> 
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Your Email" formControlName="email" required>
            <mat-icon matSuffix class="text-muted">email</mat-icon>
            <mat-error *ngIf="form.controls.email.errors?.required">Email est requis</mat-error>
            <mat-error *ngIf="form.controls.email.hasError('invalidEmail')">Adresse e-mail invalide</mat-error>
        </mat-form-field>
    </div> 
    <div fxFlex="100" fxFlex.gt-sm="100" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>choix de Date</mat-label>
            <input matInput placeholder="Pick a date" formControlName="date" required [matDatepicker]="datePicker" [min]="minDate" [max]="maxDate" appMaskDate>
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>  
            <mat-error *ngIf="form.controls.date.errors?.required">Date est requis</mat-error>
        </mat-form-field> 
    </div> 
    <div fxFlex="100" fxFlex.gt-sm="100" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Heure de Presentation</mat-label> 
            <mat-select formControlName="time" required> 
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
            </mat-select>
            <mat-icon matSuffix class="text-muted">schedule</mat-icon>
            <mat-error *ngIf="form.controls.time.errors?.required">Time est requis</mat-error>
        </mat-form-field>  
    </div>
    <div fxFlex="100" fxFlex.gt-sm="100" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Numbre d'invités</mat-label>
            <input matInput placeholder="How many of you?" formControlName="guests" required onlyNumber>
            <mat-icon matSuffix class="text-muted">group</mat-icon>
            <mat-error *ngIf="form.controls.guests.errors?.required">Guests est requis</mat-error>
        </mat-form-field> 
    </div> 
    <div fxFlex="100" fxFlex.gt-sm="100" class="px-3" ngClass.sm="mt-1" ngClass.xs="mt-1">
        
            <mat-label>Mode de Paiement</mat-label>
            <mat-radio-group aria-label="Select an option" id="modeop" (change)="processPayment($event)" formControlName="message">
                <mat-radio-button value="MOMO">MOMO</mat-radio-button>
                <mat-radio-button value="Comptant">Comptant</mat-radio-button>
              </mat-radio-group>
           
    <div *ngIf="modeOfPayment == 'MOMO'">
        <h2>MOMO</h2>
        <h3>Réservez votre table par MomoPay en composant le code *880*41*704064*Montant#</h3>
    </div>
    <div *ngIf="modeOfPayment == 'Comptant'">
        <h2>Comptant</h2>
        <h3>Réservez votre table en payant directement à la caisse lorsque vous serai au restaurant ou cliquez sur<a style="color: blue;" href='https://wa.me/+22957299988'> le lien </a> pour un RDV de paiement physique.</h3>
    </div>


    
        <!-- <mat-form-field appearance="outline" class="w-100">
            <mat-label>Détails de paiement</mat-label> 
            <input matInput placeholder="Payment Details" formControlName="message" rows="6"> 
            <mat-error *ngIf="form.controls.message.errors?.required">Détails de paiement est requis</mat-error>
        </mat-form-field> -->
    </div>  
    <div class="w-100 py-2 text-center">
        <button mat-raised-button color="primary" type="submit" class="uppercase" (click)="submitForm()" [disabled]="form.invalid">soumettre</button>
    </div>  
</form>

<div class="resp" *ngIf="emailSent">
    merci d'avoir réservé votre menu, nous vous attendons !
</div>
       
                
 