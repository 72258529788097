import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  public times = [
    { icon: 'coffee-cup', title: 'Breakfast', hours:'8.00 am 10.00 am' },
    { icon: 'lunch', title: 'Lunch', hours:'1.00 am 2.00 am' },
    { icon: 'dinner-table', title: 'Dinner', hours:'7.00 am 9.00 am' },
    { icon: 'ice-cream', title: 'Dessert', hours:'All time' }
  ]
  @Input() websiteData:any

  constructor() { }

  ngOnInit(): void {
    console.log('djdjkldsdklds');

    this.times = [
      { icon: 'coffee-cup', title: this.getWebsiteText('home.breakfast'), hours:this.getWebsiteText('home.breakfasttime') },
      { icon: 'lunch', title: this.getWebsiteText('home.lunch'), hours:this.getWebsiteText('home.lunchtime') },
      { icon: 'dinner-table', title: this.getWebsiteText('home.dinner'), hours:this.getWebsiteText('home.dinnertime') },
      { icon: 'ice-cream', title: this.getWebsiteText('home.dessert'), hours:this.getWebsiteText('home.desserttime') }
    ]

    console.log(this.websiteData);
    
  }

  getWebsiteText(desiredUid: any) {
   
    const myObject = this.websiteData.find((obj:any) => obj.id === desiredUid)

    if (myObject) {
      return myObject.text; 
    } else {
      return null
    }

  
     
  }

}
