<div class="section timeline pb-0">
    <div class="px-3">
        <div class="theme-container"> 
            <div fxLayout="row wrap" class="services-wrapper">
                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50" class="p-2"> 
                   
                    <div fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">  
                        <span class="timeline-icon primary-color" [ngClass]="'flaticon-coffee-cup'"></span>   
                        <h1 class="secondary-font capitalize fw-600 my-3"> {{getWebsiteText('home.breakfast')}}</h1>
                        <h3 class="fw-500"> {{getWebsiteText('home.breakfasttime')}}</h3>           
                    </div> 
                </div>  
                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50" class="p-2">  
                    <div fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">  
                        <span class="timeline-icon primary-color" [ngClass]="'flaticon-lunch'"></span>   
                        <h1 class="secondary-font capitalize fw-600 my-3"> {{getWebsiteText('home.lunch')}}</h1>
                        <h3 class="fw-500"> {{getWebsiteText('home.lunchtime')}}</h3>           
                    </div> 
                </div> 
                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50" class="p-2">  
                    <div fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">  
                        <span class="timeline-icon primary-color" [ngClass]="'flaticon-dinner-table'"></span>   
                        <h1 class="secondary-font capitalize fw-600 my-3"> {{getWebsiteText('home.dinner')}}</h1>
                        <h3 class="fw-500"> {{getWebsiteText('home.dinnertime')}}</h3>           
                    </div> 
                </div> 
                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-xs="50" class="p-2">  
                    <div fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">  
                        <span class="timeline-icon primary-color" [ngClass]="'flaticon-ice-cream'"></span>   
                        <h1 class="secondary-font capitalize fw-600 my-3"> {{getWebsiteText('home.dessert')}}</h1>
                        <h3 class="fw-500"> {{getWebsiteText('home.desserttime')}}</h3>           
                    </div> 
                </div> 
            </div> 
        </div>
    </div>   
</div>

 