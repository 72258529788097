<footer>    
    <div class="px-3">
        <div class="theme-container"> 
            <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="content border-lighter py-5">
                <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
                    <h1 class="secondary-font">Abonnez-vous à notre bulletin
                        </h1>
                    <p class="desc">
                        restez au courant de nos dernières nouvelles</p>
                </div>
                <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribeFormSubmit(subscribeForm.value)" class="custom-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
                    <mat-form-field appearance="outline" floatLabel="always" class="subscribe-input" fxFlex>
                        <input matInput autocomplete="off" formControlName="email" placeholder="Votre adresse e-mail...">                      
                    </mat-form-field>
                    <button mat-flat-button type="submit" class="subscribe-btn" color="primary">S'abonner</button>
                </form>
            </div> 
            <div class="py-5 content border-lighter"> 
                <div fxLayout="row wrap" fxLayoutAlign="space-between"> 
                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45"> 
                        <a class="logo" routerLink="/"></a>
                        <p class="mt-4 mb-3 desc">Le Restaurant Pizza Royal a vu le jour en avril 2019 et est spécialisé dans la confection des plats africains, européens et asiatiques.</p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                            <mat-icon color="primary">location_on</mat-icon>
                            <span class="mx-2">villa 22, Arconville, Abomey Calavi, Benin</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">call</mat-icon>
                            <span class="mx-2">+229 67 61 46 13</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">mail_outline</mat-icon>
                            <span class="mx-2">info@restauroyal.com</span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">schedule</mat-icon>
                            <span class="mx-2">Mon - Sun / 12:00PM - 00:00AM</span>
                        </p>
                        <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="start center" class="desc"></app-social-icons>                    
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5"> 
                        <h1 class="secondary-font">DONNEZ NOUS VOTRE AVIS</h1>
                        <p class="desc">Nous voulons savoir votre avis sur tout</p>
                        <form [formGroup]="feedbackForm" (ngSubmit)="onFeedbackFormSubmit(feedbackForm.value)" class="custom-form pt-2">                            
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>E-mail</mat-label> 
                                <input matInput autocomplete="off" formControlName="email">
                                <mat-error *ngIf="feedbackForm.controls.email.errors?.required">Email est requis</mat-error>
                                <mat-error *ngIf="feedbackForm.controls.email.hasError('invalidEmail')">
                                    Adresse e-mail invalide</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Votre Message</mat-label> 
                                <textarea matInput formControlName="message" rows="6"></textarea> 
                                <mat-error *ngIf="feedbackForm.controls.message.errors?.required">Message est requis</mat-error>
                            </mat-form-field>
                            <div class="w-100 text-center">
                                <button mat-flat-button color="primary" class="uppercase" type="submit">SOUMETTRE</button> 
                            </div> 
                        </form> 
                    </div>
 
                    <div fxFlex="100" fxFlex.gt-sm="30" ngClass.lt-md="pt-5"> 
                        <h1 class="secondary-font mb-3">Notre emplacement</h1>   
                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="false" [styles]="mapStyles">
                            <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false"></agm-marker>
                        </agm-map> 
                    </div>
                </div>  
            </div> 
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2">
                <p ngClass.xs="mt-1">Copyright © 2023 Tous droits réservés</p>  
                <p>Développé par <a mat-button href="http://lesgilles.com/" target="_blank">Les Gilles IT Solutions</a></p>
            </div> 
        </div>
    </div>      
</footer>