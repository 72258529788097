import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-reservation-form',
  templateUrl: './reservation-form.component.html',
  styleUrls: ['./reservation-form.component.scss']
})
export class ReservationFormComponent implements OnInit {
  public form!: UntypedFormGroup;
  public hours = ['5:00pm','6:00pm','7:00pm','8:00pm','9:00pm','10:00pm','11:00pm','12:00pm','1:00am','2:00am']; 
  public today = new Date();
  public modeOfPayment = ''
  public emailSent = false
  

  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter(); 
  constructor(public formBuilder: UntypedFormBuilder, public appService: AppService) { }

  @Input() data :string | undefined

  minDate = new Date(2023, 11, 30); // December is 11 (0-indexed)
  maxDate = new Date(2023, 11, 31);

  ngOnInit(): void { 
    this.form = this.formBuilder.group({
      fullName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, emailValidator])], 
      guests: [null, Validators.required],
      date: [null, Validators.required],
      time: [null, Validators.required],
      message: [null, Validators.required],
    });
  }

  public submit(){
    console.log(this.form.value);
    if(this.form.valid){
      this.onFormSubmit.emit(this.form);
    } 
  }

  processPayment(e:any) {
    console.log(e.detail);
    this.modeOfPayment = this.form.controls['message'].value
    console.log(this.modeOfPayment, "mode of payment");
    
  }

  submitForm() {
    console.log(this.data, "food option");
    
    const data = {
      
      to: 'restaupizzaroyal@gmail.com, info@lesgilles.com',
      message: {
        subject: 'Reservation from Website!',
        html:  "Name:" + this.form.controls['fullName'].value  + "<br>" +  "Phone Number:" + this.form.controls['phoneNumber'].value  + "<br>" +  "email:" + this.form.controls['email'].value  + "<br>" +  "Guests:" + this.form.controls['guests'].value  + "<br>" +  "Date:" + this.form.controls['date'].value  + "<br>" +  "Time:" + this.form.controls['time'].value  + "<br>" +  "Payment Mode:" + this.form.controls['message'].value + "<br>" +  "Food:" + "<img src='https://www.restauroyal.com/" + this.data + "' width='300px' height='250px'>"
      },
        

      fullName: this.form.controls['fullName'].value,
      phoneNumber: this.form.controls['phoneNumber'].value,
      email: this.form.controls['email'].value, 
      guests: this.form.controls['guests'].value,
      date: this.form.controls['date'].value,
      time: this.form.controls['time'].value,
      paymentMode: this.form.controls['message'].value,

  }

  this.appService.emailData(data).then(() => {
    this.form.reset
    this.emailSent = true

  })
}
}
