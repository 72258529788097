<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">call</mat-icon>
  <span class="px-1">+229 67 61 46 13</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <span class="px-1">Villa 22, Arconville, Abomey Calavi, Benin</span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-sm></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-sm>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">mail</mat-icon>
  <span class="px-1">info@restauroyal.com</span>
</span>
