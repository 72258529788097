<div class="px-3">
    <div class="theme-container">  
        <div class="get-in-touch bg-primary" ngClass.lt-md="mt-3"> 
            <img src="{{getWebsiteText('home.footertopimage')}}" alt="operator" fxShow="false" fxShow.gt-xs> 
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="content" ngClass.xs="p-0"> 
                <div fxLayout="column" class="py-2 px-3">
                    <h1 class="secondary-font">{{getWebsiteText('home.footertoptitle')}}</h1>
                    <p class="mb-0">{{getWebsiteText('home.footertopsubtitle')}}</p>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="py-2 px-3">
                    <mat-icon class="mat-icon-xlg mx-2">call</mat-icon>
                    <div fxLayout="column">               
                        <p class="mb-0">{{getWebsiteText('home.footertopcallusnow')}}</p>
                        <h2 class="ws-nowrap">{{getWebsiteText('home.footertopphone')}}</h2>
                    </div>
                </div>
                <div class="py-2 px-3">
                    <a mat-flat-button routerLink="/contact" class="mx-3 uppercase">{{getWebsiteText('home.footerfeedbackbutton')}}</a>
                </div>
            </div>  
        </div> 
    </div>
</div>