<div class="section">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">{{getWebsiteText('home.servicestitle')}}</h1> 
            <p class="section-desc text-muted">{{getWebsiteText('home.servicessubtitle')}}</p> 

            <div fxLayout="row wrap" class="services-wrapper">
                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">fastfood</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">{{getWebsiteText('home.service1title')}}</h2>
                        <p>{{getWebsiteText('home.service1text')}}</p>           
                    </mat-card> 
                </div>
                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">local_shipping</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">{{getWebsiteText('home.service2title')}}</h2>
                        <p>{{getWebsiteText('home.service2text')}}</p>             
                    </mat-card> 
                </div>

                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">local_offer</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">{{getWebsiteText('home.service3title')}}</h2>
                        <p>{{getWebsiteText('home.service3text')}}</p>             
                    </mat-card> 
                </div>

                <div fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-2"> 
                    <mat-card fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">            
                        <mat-icon class="mat-icon-xlg" color="primary">more_time</mat-icon>
                        <h2 class="secondary-font capitalize fw-600 my-3">{{getWebsiteText('home.service4title')}}</h2>
                        <p>{{getWebsiteText('home.service4text')}}</p>             
                    </mat-card> 
                </div> 

            </div>
                
        </div>
    </div>   
</div>