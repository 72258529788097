// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: '',
  firebase: {
    projectId: 'restaurantroyal-bf6ed',
    appId: '1:557147699590:web:f69029a496135b72c4d407',
    storageBucket: 'restaurantroyal-bf6ed.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCVYzjmwmlKQ5PMsWC9WkThg3jZPKIL1QY',
    authDomain: 'restaurantroyal-bf6ed.firebaseapp.com',
    messagingSenderId: '557147699590',
    measurementId: 'G-9YYNL2KXJ0',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
