import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {
@Input() websiteData:any

  constructor() { }

  ngOnInit(): void { 
    console.log(this.websiteData);
    
  }

  getWebsiteText(desiredUid: any) {
   
    const myObject = this.websiteData.find((obj:any) => obj.id === desiredUid)

    if (myObject) {
      return myObject.text; 
    } else {
      return null
    }

  
     
  }
}
