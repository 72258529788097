import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {

  @Input() websiteData:any

  constructor() { }

  ngOnInit(): void { 
  }

  getWebsiteText(desiredUid: any) {
   
    const myObject = this.websiteData.find((obj:any) => obj.id === desiredUid)

    if (myObject) {
      return myObject.text; 
    } else {
      return null
    }

  
     
  }

}
