<a href="https://www.facebook.com/restaurantpizzaroyal" class="social-icon">
  <svg class="mat-icon" viewBox="0 0 24 24" [ngClass]="['mat-icon-' + iconSize, 'mat-'+ iconColor]">
    <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
  </svg>
</a>

<a href="https://www.google.com/maps/place/RESTAURANT+PIZZA+ROYAL+ARCONVILLE/@6.4718242,2.3552527,15z/data=!4m6!3m5!1s0x1024a95d3f4d9a51:0x1603f0107fa558d0!8m2!3d6.4718242!4d2.3552527!16s%2Fg%2F11hz0x9wcn?hl=fr-CA" class="social-icon"> 
  <svg class="mat-icon" viewBox="0 0 24 24" [ngClass]="['mat-icon-' + iconSize, 'mat-'+ iconColor]">
    <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M19.5,12H18V10.5H17V12H15.5V13H17V14.5H18V13H19.5V12M9.65,11.36V12.9H12.22C12.09,13.54 11.45,14.83 9.65,14.83C8.11,14.83 6.89,13.54 6.89,12C6.89,10.46 8.11,9.17 9.65,9.17C10.55,9.17 11.13,9.56 11.45,9.88L12.67,8.72C11.9,7.95 10.87,7.5 9.65,7.5C7.14,7.5 5.15,9.5 5.15,12C5.15,14.5 7.14,16.5 9.65,16.5C12.22,16.5 13.96,14.7 13.96,12.13C13.96,11.81 13.96,11.61 13.89,11.36H9.65Z" />
  </svg>
</a>
<a href="https://www.instagram.com/restaurantpizzaroyal" class="social-icon"> 
  <svg class="mat-icon" viewBox="0 -1 26 26" [ngClass]="['mat-icon-' + iconSize, 'mat-'+ iconColor]">
    <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
  </svg>
</a>
<a href="https://wa.me/+22967614613" class="social-icon"> 
  <img  src="../../../../../assets/images/1w.png" height="25px" width="25px"/>
</a>

 